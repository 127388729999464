import $ from "jquery";

let section = function () {
    let video1 = $("#menu-video-1");
    let video2 = $("#menu-video-2");
    let video3 = $("#menu-video-3");
    let video4 = $("#menu-video-4");
    let video5 = $("#menu-video-5");

    let screen2 = $("#screen-2")

    function listener(n, num) {
        setTimeout(() => {
            screen2.find("img").get(num).classList.add("poster")
        }, 400)
        return n.get(0).play();
    }

    function play_menu() {
        screen2.find("img").get(0).classList.add("poster")
        video1.get(0).play()
        video1.get(0).addEventListener("ended", () => listener(video2, 1), true);
        video2.get(0).addEventListener("ended", () => listener(video3, 2), true);
        video3.get(0).addEventListener("ended", () => listener(video4, 3), true);
        video4.get(0).addEventListener("ended", () => listener(video5, 4), true);
    }

    play_menu();
}
export {section}

