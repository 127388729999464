<template>
  <section id="screen-2" class="screen">

    <router-link v-for="item in menuItem" :key="item.h1" :to="item.src"
                 class="menu-item">
      <div class="title-wrap">
        <span class="title">{{ item.h1 }}</span>
      </div>
      <div class="menu-gradient"></div>
      <img id="img-poster" :class="{'offlinePoster' : !networkStatus}"
           :src="require(`@/assets/video/posters/${item.video}.jpg`)"
           alt="poster">
      <video v-if="networkStatus" :id="item.id" class="menu-video" muted
             playsinline preload="auto">
        <source :src="`https://porsche.dev.mvplab.org/media/${item.video}.mp4`" type="video/mp4">
      </video>
    </router-link>
  </section>
</template>

<script>
import {getMenuItem} from "@/data";
import {section} from '@/functions/menu'
import {VueOfflineMixin} from 'vue-offline'


export default {
  name: "VMenu.vue",
  mixins: [VueOfflineMixin],
  data: function () {
    return {
      menuItem: [],
    }
  },
  mounted() {
    this.menuItem = getMenuItem()
  },

  updated() {
    section()
  },

  computed: {
    networkStatus() {
      return !!this.isOnline
    }
  }
}
</script>